// src/components/WelcomeAnimation/WelcomeAnimationStyle.js

import styled, { keyframes } from 'styled-components';
import ilan from '../../assets/ilan/ilan.jpg'; // Asegúrate de que la ruta sea correcta

// Animación de aparición
export const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

// Animación de desaparición (opcional, si deseas agregarla)
export const fadeOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

export const WelcomeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Cubre toda la pantalla */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${ilan});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 9999;
    animation: ${fadeIn} 3s ease-in-out forwards; /* Duración extendida a 3 segundos */
    cursor: pointer;

    /* Superposición oscura para mejorar la legibilidad del texto */
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0; /* Asegura que la superposición esté por debajo del contenido */
    }

    /* Asegura que el contenido esté por encima de la superposición */
    & > div {
        position: relative;
        z-index: 1;
        text-align: center;
        padding: 20px;
    }

    /* Responsividad */
    @media (max-width: 768px) {
        & > div {
            padding: 10px;
        }
    }
`;

export const WelcomeMessage = styled.h1`
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 2rem;
        padding: 0 10px;
    }
`;
// FormBuilder.jsx
import React, { useState } from 'react';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import {
    BuilderContainer,
    Toolbox,
    ToolboxItem,
    Canvas,
    PropertyEditorWrapper,
    PropertyEditorField,
    SaveButton,
    LoadButton,
    TitleArea,
    TitleEditor,
    LogoColumn,
    FormElementWrapper,
    ColumnWrapper,
    ColumnHeader,
    AddColumnButton,
    DeleteColumnButton,
    FavoritesIcon,
    Modal,
    ModalContent,
    CloseButton,
    FavoritesItem,
    Preview
} from './FormBuilderStyle';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { AiOutlineFileText, AiOutlineCheckSquare, AiOutlineNumber, AiOutlineMail, AiOutlineDown, AiOutlinePicture } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';

const GridLayout = WidthProvider(ReactGridLayout);

const FormBuilder = () => {
    const [formElements, setFormElements] = useState([]);
    const [layout, setLayout] = useState([]);
    const [selectedElementId, setSelectedElementId] = useState(null);
    const [favoriteForms, setFavoriteForms] = useState(
        () => JSON.parse(localStorage.getItem('favoriteForms')) || []
    );
    const [formTitle, setFormTitle] = useState('Título de la Ficha');
    const [columns, setColumns] = useState([]);
    const [logo, setLogo] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);

    const addColumn = () => {
        const newColumnId = `column-${Date.now()}`;

        const rows = groupColumnsByRow(layout);
        let targetRowY = 0;

        let foundRow = false;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].length < 2) { // Limitar a 2 columnas por fila en pantallas pequeñas
                targetRowY = rows[i][0].y;
                foundRow = true;
                break;
            }
        }

        if (!foundRow) {
            targetRowY = rows.length > 0 ? Math.max(...rows.map(row => row[0].y)) + 1 : 0;
        }

        const columnsInRow = layout.filter(
            (item) => item.y === targetRowY && item.i.startsWith('column-')
        );

        if (columnsInRow.length >= 2) {
            alert('No se pueden agregar más de 2 columnas en una fila en pantallas pequeñas.');
            return;
        }

        const totalColumns = columnsInRow.length + 1;
        const newWidth = Math.floor(12 / totalColumns);

        let newLayout = layout.map((item) => {
            if (item.y === targetRowY && item.i.startsWith('column-')) {
                return { ...item, w: newWidth };
            }
            return item;
        });

        let x = 0;
        newLayout = newLayout.map((item) => {
            if (item.y === targetRowY && item.i.startsWith('column-')) {
                const newItem = { ...item, x };
                x += item.w;
                return newItem;
            }
            return item;
        });

        const newLayoutItem = {
            i: newColumnId,
            x: x,
            y: targetRowY,
            w: newWidth,
            h: 1,
        };

        setColumns([...columns, { id: newColumnId }]);
        setLayout([...newLayout, newLayoutItem]);
    };

    const removeColumn = (columnId) => {
        const columnItem = layout.find((item) => item.i === columnId);
        const columnY = columnItem ? columnItem.y : null;

        const newColumns = columns.filter((col) => col.id !== columnId);
        const newFormElements = formElements.filter((el) => el.parentId !== columnId);
        let newLayout = layout.filter((item) => item.i !== columnId);

        if (columnY !== null) {
            const columnsInRow = newLayout.filter(
                (item) => item.y === columnY && item.i.startsWith('column-')
            );

            const totalColumns = columnsInRow.length;
            if (totalColumns > 0) {
                const newWidth = Math.floor(12 / totalColumns);
                let x = 0;
                newLayout = newLayout.map((item) => {
                    if (item.y === columnY && item.i.startsWith('column-')) {
                        const newItem = { ...item, w: newWidth, x };
                        x += newWidth;
                        return newItem;
                    }
                    return item;
                });
            }
        }

        setColumns(newColumns);
        setFormElements(newFormElements);
        setLayout(newLayout);
    };

    const onLayoutChange = (newLayout) => {
        const adjustedLayout = adjustLayout(newLayout);
        setLayout(adjustedLayout);
    };

    const adjustLayout = (layout) => {
        const rows = groupColumnsByRow(layout);

        rows.forEach((rowItems) => {
            rowItems.sort((a, b) => a.x - b.x);

            const totalWidth = rowItems.reduce((sum, item) => sum + item.w, 0);

            if (totalWidth !== 12) {
                const scale = 12 / totalWidth;
                rowItems.forEach((item) => {
                    item.w = Math.max(1, Math.round(item.w * scale));
                });
            }

            let x = 0;
            rowItems.forEach((item) => {
                item.x = x;
                x += item.w;
            });
        });

        return layout;
    };

    const groupColumnsByRow = (layout) => {
        const rows = {};
        layout.forEach((item) => {
            if (item.i.startsWith('column-')) {
                const y = item.y;
                if (!rows[y]) {
                    rows[y] = [];
                }
                rows[y].push(item);
            }
        });
        return Object.values(rows).sort((a, b) => a[0].y - b[0].y);
    };

    const addFormElement = (elementType, parentId) => {
        const newElementId = `element-${Date.now()}`;
        const newElement = {
            id: newElementId,
            parentId,
            type: elementType,
            label: 'Etiqueta',
            name: '',
            options: elementType === 'select' ? ['Opción 1', 'Opción 2'] : undefined,
            src: '',
            width: 100,
        };
        setFormElements([...formElements, newElement]);
    };

    const updateFormElement = (elementId, newElement) => {
        setFormElements((prevElements) => {
            return prevElements.map((el) => (el.id === elementId ? newElement : el));
        });
    };

    const handleSave = () => {
        const formName = prompt('Ingrese un nombre para el formulario:');
        if (formName) {
            const savedForms = JSON.parse(localStorage.getItem('savedForms')) || {};
            savedForms[formName] = generateFormJSON();
            localStorage.setItem('savedForms', JSON.stringify(savedForms));
            setFavoriteForms([...favoriteForms, formName]);
            localStorage.setItem('favoriteForms', JSON.stringify([...favoriteForms, formName]));
            alert('Formulario guardado exitosamente.');
        }
    };

    const handleLoad = (formName = null) => {
        const savedForms = JSON.parse(localStorage.getItem('savedForms')) || {};
        const formNames = Object.keys(savedForms);
        if (formNames.length === 0) {
            alert('No hay formularios guardados.');
            return;
        }
        if (!formName) {
            formName = prompt('Ingrese el nombre del formulario a cargar:', formNames[0]);
        }
        if (formName && savedForms[formName]) {
            const loadedForm = savedForms[formName];
            setFormTitle(loadedForm.title || 'Título de la Ficha');
            setFormElements(loadedForm.formElements);
            setLayout(loadedForm.layout || []);
            setColumns(loadedForm.columns || []);
            setLogo(loadedForm.logo || null);
            alert('Formulario cargado exitosamente.');
        } else {
            alert('Formulario no encontrado.');
        }
    };

    const generateFormJSON = () => {
        const formStructure = {
            title: formTitle,
            logo: logo,
            layout: layout,
            columns: columns.map((col) => {
                const colLayout = layout.find((l) => l.i === col.id);
                const colElements = formElements.filter((el) => el.parentId === col.id);
                return {
                    id: col.id,
                    layout: colLayout,
                    elements: colElements,
                };
            }),
            formElements: formElements,
        };
        return formStructure;
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const toggleFavorites = () => {
        setShowFavorites(!showFavorites);
    };

    const loadFavoriteForm = (formName) => {
        handleLoad(formName);
        setShowFavorites(false);
    };

    const onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
        onLayoutChange(layout);
    };

    return (
        <div>
            <BuilderContainer>
                <FavoritesIcon onClick={toggleFavorites} />
                {showFavorites && (
                    <Modal>
                        <ModalContent>
                            <CloseButton onClick={toggleFavorites} />
                            <h3>Formularios Guardados</h3>
                            {favoriteForms.map((formName, index) => (
                                <FavoritesItem key={index} onClick={() => loadFavoriteForm(formName)}>
                                    {formName}
                                </FavoritesItem>
                            ))}
                        </ModalContent>
                    </Modal>
                )}
                <Toolbox>
                    <h3>Herramientas</h3>
                    <DraggableToolboxItem type="input" label="Entrada de texto" icon={<AiOutlineFileText />} />
                    <DraggableToolboxItem type="checkbox" label="Casilla de verificación" icon={<AiOutlineCheckSquare />} />
                    <DraggableToolboxItem type="number" label="Entrada numérica" icon={<AiOutlineNumber />} />
                    <DraggableToolboxItem type="email" label="Entrada de correo" icon={<AiOutlineMail />} />
                    <DraggableToolboxItem type="select" label="Desplegable" icon={<AiOutlineDown />} />
                    <DraggableToolboxItem type="image" label="Imagen" icon={<AiOutlinePicture />} />
                    <AddColumnButton onClick={addColumn}>
                        <IoMdAddCircleOutline /> Añadir Columna
                    </AddColumnButton>
                    <SaveButton onClick={handleSave}>Guardar Formulario</SaveButton>
                    <LoadButton onClick={() => handleLoad()}>Cargar Formulario</LoadButton>
                    <Preview onClick={togglePreview}>Vista Previa</Preview>
                </Toolbox>
                <CanvasArea
                    formTitle={formTitle}
                    setFormTitle={setFormTitle}
                    formElements={formElements}
                    updateFormElement={updateFormElement}
                    selectedElementId={selectedElementId}
                    setSelectedElementId={setSelectedElementId}
                    columns={columns}
                    layout={layout}
                    onLayoutChange={onLayoutChange}
                    addFormElement={addFormElement}
                    removeColumn={removeColumn}
                    logo={logo}
                    setLogo={setLogo}
                    onDragStop={onDragStop}
                />
                <PropertyEditor
                    element={formElements.find((el) => el.id === selectedElementId)}
                    updateElement={(updatedElement) => {
                        updateFormElement(selectedElementId, updatedElement);
                    }}
                />
                {showPreview && (
                    <Modal>
                        <ModalContent>
                            <CloseButton onClick={togglePreview} />
                            <FormPreview formJSON={generateFormJSON()} />
                        </ModalContent>
                    </Modal>
                )}
            </BuilderContainer>
        </div>
    );
};

const DraggableToolboxItem = ({ type, label, icon }) => {
    const onDragStart = (e) => {
        e.dataTransfer.setData('componentType', type);
    };

    return (
        <ToolboxItem draggable onDragStart={onDragStart}>
            {icon} {label}
        </ToolboxItem>
    );
};

const CanvasArea = ({
                        formTitle,
                        setFormTitle,
                        formElements,
                        updateFormElement,
                        selectedElementId,
                        setFormElements,
                        setSelectedElementId,
                        columns,
                        layout,
                        onLayoutChange,
                        addFormElement,
                        removeColumn,
                        logo,
                        setLogo,
                        onDragStop,
                    }) => {
    const onDropElement = (event, columnId) => {
        const elementType = event.dataTransfer.getData('componentType');
        if (elementType) {
            addFormElement(elementType, columnId);
        }
    };

    const onDragStartElement = (e, element) => {
        e.stopPropagation();
        e.dataTransfer.setData('elementId', element.id);
        e.dataTransfer.setData('parentId', element.parentId);
    };

    const onDropElementOnColumn = (e, newParentId) => {
        e.preventDefault();
        const elementId = e.dataTransfer.getData('elementId');

        if (elementId) {
            setFormElements((prevElements) =>
                prevElements.map((el) =>
                    el.id === elementId ? { ...el, parentId: newParentId } : el
                )
            );
        }
    };

    const generateColumns = () => {
        return columns.map((column) => (
            <div key={column.id} data-grid={layout.find((l) => l.i === column.id)}>
                <ColumnWrapper>
                    <DeleteColumnButton onClick={() => removeColumn(column.id)} />
                    <ColumnHeader className="column-drag-handle">
                        <span>Columna</span>
                    </ColumnHeader>
                    <div
                        className="column-content"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => onDropElementOnColumn(e, column.id)}
                    >
                        {formElements
                            .filter((el) => el.parentId === column.id)
                            .map((element) => (
                                <FormElement
                                    key={element.id}
                                    element={element}
                                    updateFormElement={updateFormElement}
                                    isSelected={selectedElementId === element.id}
                                    onClick={() => setSelectedElementId(element.id)}
                                    draggable
                                    onDragStart={(e) => onDragStartElement(e, element)}
                                />
                            ))}
                        <div
                            className="drop-zone"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => onDropElement(e, column.id)}
                        >
                            <span className="add-icon">+</span>
                        </div>
                    </div>
                </ColumnWrapper>
            </div>
        ));
    };

    return (
        <Canvas>
            <TitleArea>
                <TitleEditor
                    value={formTitle}
                    onChange={(e) => setFormTitle(e.target.value)}
                    placeholder="Título de la Ficha"
                />
                <LogoColumn>
                    <label htmlFor="logo-upload">Logo:</label>
                    <input
                        id="logo-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                const reader = new FileReader();
                                reader.onload = () => {
                                    setLogo(reader.result);
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                    {logo && <img src={logo} alt="Logo" />}
                </LogoColumn>
            </TitleArea>
            <GridLayout
                className="layout"
                layout={layout}
                cols={12}
                rowHeight={150}
                width={1200}
                margin={[10, 30]}
                onLayoutChange={onLayoutChange}
                onResizeStop={onLayoutChange}
                onDragStop={onDragStop}
                isResizable
                isDraggable
                compactType={null}
                resizeHandles={['e', 'w']}
                preventCollision={false}
                useCSSTransforms={true}
                draggableHandle=".column-drag-handle"
            >
                {generateColumns()}
            </GridLayout>
        </Canvas>
    );
};

const FormElement = ({
                         element,
                         updateFormElement,
                         isSelected,
                         onClick,
                         draggable,
                         onDragStart,
                     }) => {
    const renderElement = () => {
        if (!element) return null;

        switch (element.type) {
            case 'input':
                return (
                    <label style={{ width: `${element.width}%` }}>
                        <span
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => {
                                updateFormElement(element.id, { ...element, label: e.target.textContent });
                            }}
                        >
                            {element.label}
                        </span>
                        <input type="text" name={element.name} readOnly />
                    </label>
                );
            case 'checkbox':
                return (
                    <label style={{ width: `${element.width}%` }}>
                        <input type="checkbox" name={element.name} readOnly />
                        {element.label}
                    </label>
                );
            case 'number':
                return (
                    <label style={{ width: `${element.width}%` }}>
                        <span
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => {
                                updateFormElement(element.id, { ...element, label: e.target.textContent });
                            }}
                        >
                            {element.label}
                        </span>
                        <input type="number" name={element.name} readOnly />
                    </label>
                );
            case 'email':
                return (
                    <label style={{ width: `${element.width}%` }}>
                        <span
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => {
                                updateFormElement(element.id, { ...element, label: e.target.textContent });
                            }}
                        >
                            {element.label}
                        </span>
                        <input type="email" name={element.name} readOnly />
                    </label>
                );
            case 'select':
                return (
                    <label style={{ width: `${element.width}%` }}>
                        <span
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => {
                                updateFormElement(element.id, { ...element, label: e.target.textContent });
                            }}
                        >
                            {element.label}
                        </span>
                        <select name={element.name} disabled>
                            {element.options &&
                                element.options.map((opt, idx) => (
                                    <option key={idx} value={opt}>
                                        {opt}
                                    </option>
                                ))}
                        </select>
                    </label>
                );
            case 'image':
                return (
                    <label style={{ width: `${element.width}%` }}>
                        <span
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => {
                                updateFormElement(element.id, { ...element, label: e.target.textContent });
                            }}
                        >
                            {element.label}
                        </span>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                        updateFormElement(element.id, { ...element, src: reader.result });
                                    };
                                    reader.readAsDataURL(file);
                                }
                            }}
                        />
                        {element.src && (
                            <img src={element.src} alt="Imagen" style={{ width: '100%', maxHeight: '100px' }} />
                        )}
                    </label>
                );
            default:
                return null;
        }
    };

    return (
        <FormElementWrapper
            isSelected={isSelected}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
            draggable={draggable}
            onDragStart={onDragStart}
            style={{ width: `${element.width}%` }}
        >
            {renderElement()}
        </FormElementWrapper>
    );
};

const PropertyEditor = ({ element, updateElement }) => {
    if (!element) {
        return (
            <PropertyEditorWrapper>
                Seleccione un elemento para editar sus propiedades.
            </PropertyEditorWrapper>
        );
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateElement({ ...element, [name]: value });
    };

    const handleOptionsChange = (e) => {
        const options = e.target.value.split(',').map((opt) => opt.trim());
        updateElement({ ...element, options });
    };

    const handleWidthChange = (e) => {
        const width = parseInt(e.target.value, 10);
        if (width >= 10 && width <= 100) {
            updateElement({ ...element, width });
        }
    };

    return (
        <PropertyEditorWrapper>
            <h3>Propiedades</h3>
            <PropertyEditorField>
                <label>Etiqueta:</label>
                <input type="text" name="label" value={element.label} onChange={handleChange} />
            </PropertyEditorField>
            <PropertyEditorField>
                <label>Nombre:</label>
                <input type="text" name="name" value={element.name} onChange={handleChange} />
            </PropertyEditorField>
            {element.type === 'select' && (
                <PropertyEditorField>
                    <label>Opciones (separadas por coma):</label>
                    <input type="text" value={element.options.join(', ')} onChange={handleOptionsChange} />
                </PropertyEditorField>
            )}
            <PropertyEditorField>
                <label>Ancho (%):</label>
                <input
                    type="range"
                    name="width"
                    value={element.width}
                    min="10"
                    max="100"
                    onChange={handleWidthChange}
                />
                <span>{element.width}%</span>
            </PropertyEditorField>
        </PropertyEditorWrapper>
    );
};

const FormPreview = ({ formJSON }) => {
    if (!formJSON) return null;

    return (
        <div>
            <h4>{formJSON.title}</h4>
            {formJSON.logo && <img src={formJSON.logo} alt="Logo" style={{ maxWidth: '100%' }} />}
            {formJSON.columns.map((column, colIndex) => (
                <div
                    key={colIndex}
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: `${(column.layout.w / 12) * 100}%`,
                        padding: '10px',
                        boxSizing: 'border-box',
                    }}
                >
                    {column.elements.map((element, idx) => (
                        <div key={idx} style={{ width: `${element.width}%`, marginBottom: '10px' }}>
                            {(() => {
                                switch (element.type) {
                                    case 'input':
                                        return (
                                            <label>
                                                {element.label}
                                                <input type="text" name={element.name} />
                                            </label>
                                        );
                                    case 'checkbox':
                                        return (
                                            <label>
                                                <input type="checkbox" name={element.name} />
                                                {element.label}
                                            </label>
                                        );
                                    case 'number':
                                        return (
                                            <label>
                                                {element.label}
                                                <input type="number" name={element.name} />
                                            </label>
                                        );
                                    case 'email':
                                        return (
                                            <label>
                                                {element.label}
                                                <input type="email" name={element.name} />
                                            </label>
                                        );
                                    case 'select':
                                        return (
                                            <label>
                                                {element.label}
                                                <select name={element.name}>
                                                    {element.options.map((opt, idx) => (
                                                        <option key={idx} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        );
                                    case 'image':
                                        return (
                                            <div>
                                                {element.label}
                                                {element.src && (
                                                    <img src={element.src} alt="Imagen" style={{ width: '100%' }} />
                                                )}
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default FormBuilder;
// WebSocketContext.js

import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { API_BASE_URL_SOCKET } from "../utils/config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const shownMessagesRef = useRef(new Set()); // Usamos useRef para mantener el estado entre renders
    const reconnectIntervalRef = useRef(2000); // Inicializar el intervalo de reconexión
    const maxReconnectInterval = 30000; // Máximo 30 segundos

    useEffect(() => {
        let isMounted = true;
        let webSocket = null;
        let reconnectTimeout = null;

        const connectWebSocket = () => {
            console.log('Intentando conectar WebSocket...');
            webSocket = new WebSocket(API_BASE_URL_SOCKET);

            webSocket.onopen = () => {
                console.log('WebSocket connected');
                reconnectIntervalRef.current = 2000; // Reiniciar el intervalo al conectarse exitosamente
            };

            webSocket.onmessage = (event) => {
                console.log('Mensaje recibido del servidor:', event.data);
                try {
                    const message = JSON.parse(event.data);
                    if (message.type === 'NOTIFICATION') {
                        // Manejar notificación
                        setNotifications((prev) => [...prev, message.payload]);
                        toast.info(message.payload.MENSAJE);
                    } else if (message.type === 'WHATSAPP_MESSAGE') {
                        // Manejar mensajes de WhatsApp
                        setNotifications((prev) => [...prev, {
                            ID_NOTIFICACION: Date.now(),
                            TITULO: 'Nuevo mensaje de WhatsApp',
                            MENSAJE: `Nuevo mensaje de ${message.payload.phone}`,
                            LEIDO: 0,
                            FECHA_LOCAL: new Date().toISOString()
                        }]);
                    } else if (message.type === 'CONNECTED') {
                        console.log('Conectado al servidor WebSocket.');
                    } else {
                        console.warn('Tipo de mensaje no manejado:', message.type);
                    }
                } catch (error) {
                    console.error('Error al parsear el mensaje del WebSocket:', error);
                }
            };

            webSocket.onclose = (event) => {
                console.log(`WebSocket disconnected. Código: ${event.code}, Razón: ${event.reason}. Reintentando en ${reconnectIntervalRef.current / 1000} segundos...`);
                if (isMounted) {
                    reconnectTimeout = setTimeout(() => {
                        connectWebSocket();
                        // Incrementar el intervalo de reconexión hasta el máximo
                        reconnectIntervalRef.current = Math.min(reconnectIntervalRef.current * 2, maxReconnectInterval);
                    }, reconnectIntervalRef.current);
                }
            };

            webSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
                webSocket.close();
            };

            setWs(webSocket);
        };

        connectWebSocket();

        return () => {
            isMounted = false;
            if (webSocket) {
                webSocket.close();
                console.log('WebSocket cerrado desde WebSocketProvider');
            }
            if (reconnectTimeout) {
                clearTimeout(reconnectTimeout);
            }
        };
    }, []); // Eliminar 'ws' de las dependencias

    const closeWebSocket = () => {
        if (ws) {
            ws.close();
            console.log('WebSocket cerrado manualmente');
        }
    };

    return (
        <WebSocketContext.Provider value={{ ws, closeWebSocket, notifications, setNotifications }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
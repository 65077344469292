import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LinkPreview = ({ url }) => {
    const [linkData, setLinkData] = useState(null);
    const API_KEY = '9ff0c8259c432387e8d2c14440252abc';

    useEffect(() => {
        const fetchLinkData = async () => {
            try {
                const response = await axios.get('https://api.linkpreview.net', {
                    params: {
                        key: API_KEY,
                        q: url
                    }
                });
                setLinkData(response.data);
            } catch (error) {
                console.error('Error al obtener los datos del enlace:', error);
            }
        };

        if (url) {
            fetchLinkData();
        }
    }, [url]);

    if (!linkData) {
        return null;
    }

    return (
        <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '10px', marginTop: '5px' }}>
            {linkData.image && <img src={linkData.image} alt={linkData.title} style={{ width: '100%', borderRadius: '4px' }} />}
            <div>
                <strong>{linkData.title}</strong>
            </div>
            <p>{linkData.description}</p>
            <a href={linkData.url} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff' }}>
                {linkData.url}
            </a>
        </div>
    );
};

export default LinkPreview;
// sessionToken.js

import { useEffect } from 'react';
import { useWebSocket } from "../context/WebSocketContext";
import { toast } from 'react-toastify';

const useSessionVerification = (isAuthenticated, userData, logout) => {
    const { ws } = useWebSocket();

    useEffect(() => {
        if (!isAuthenticated || !userData || !ws) {
            return;
        }

        const handleMessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                if (message.type === 'DISCONNECT' && !isExceptionUser(userData)) {
                    logout();
                    toast.warn("Tu sesión ha sido cerrada porque se inició sesión en otro lugar.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        const isExceptionUser = (user) => {
            const allowedUserIds = [314, 377];
            return allowedUserIds.includes(user.id_usuario);
        };


        ws.addEventListener('message', handleMessage);

        const verifySession = () => {
            if (ws.readyState === WebSocket.OPEN) {
                const sessionToken = localStorage.getItem('sessionToken');
                const userId = userData.id_usuario;
                ws.send(JSON.stringify({ type: 'VERIFY_SESSION', payload: { userId, sessionToken } }));
            }
        };

        verifySession();

        return () => {
            ws.removeEventListener('message', handleMessage);
        };
    }, [isAuthenticated, userData, logout, ws]);
};

export default useSessionVerification;
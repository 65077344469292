// FormBuilderStyle.js
import styled from 'styled-components';
import { AiOutlineStar, AiFillStar, AiOutlineClose, AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';

export const BuilderContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    background-color: var(--gris);

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;


export const Toolbox = styled.div`
    width: 100%;
    padding: 15px;
    background-color: #202424;
    color: var(--blanco);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--gris-oscuro);

    @media(min-width: 768px) {
        width: 240px;
        flex-direction: column;
        align-items: center;
        border-bottom: none;
        border-right: 2px solid var(--gris-oscuro);
    }
`;

export const ToolboxItem = styled.div`
    width: calc(50% - 10px);
    padding: 10px;
    margin-bottom: 10px;
    background-color: var(--gris);
    color: var(--negro);
    cursor: grab;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: var(--gris-oscuro);
        color: var(--negro);
    }

    @media(min-width: 768px) {
        width: 100%;
    }
`;

export const Canvas = styled.div`
    flex-grow: 1;
    padding: 15px;
    background-color: var(--blanco);
    overflow-y: auto;
`;

export const TitleArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @media(min-width: 768px) {
        flex-direction: row;
    }
`;

export const TitleEditor = styled.input`
    font-size: 24px;
    font-weight: bold;
    color: var(--negro);
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 8px;
    border-bottom: 2px solid var(--verde-medio);
    outline: none;
    margin-bottom: 15px;

    &:focus {
        border-bottom: 2px solid var(--verde-oscuro);
    }

    @media(min-width: 768px) {
        font-size: 28px;
        width: calc(100% - 200px);
        margin-bottom: 0;
    }
`;

export const LogoColumn = styled.div`
    width: 100%;
    text-align: center;

    img {
        max-width: 100%;
        max-height: 80px;
        margin-top: 10px;
        border: 2px solid var(--verde-oscuro);
        border-radius: 8px;
    }

    @media(min-width: 768px) {
        width: 180px;
    }
`;

export const PropertyEditorWrapper = styled.div`
    width: 100%;
    padding: 15px;
    border-top: 2px solid var(--gris-oscuro);
    background-color: var(--gris);
    overflow-y: auto;

    @media(min-width: 768px) {
        width: 300px;
        padding: 20px;
        border-top: none;
        border-left: 2px solid var(--gris-oscuro);
    }
`;

export const PropertyEditorField = styled.div`
    margin-bottom: 10px;

    label {
        display: block;
        font-weight: bold;
        color: var(--azul);
    }

    input,
    select {
        width: 100%;
        padding: 8px;
        margin-top: 5px;
        border: 1px solid var(--verde-oscuro);
        border-radius: 4px;
    }

    input[type="range"] {
        background-color: var(--verde-medio);
    }
`;

export const SaveButton = styled.button`
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 14px;
    width: 100%;
    background-color: #63e125;
    color: var(--negro);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #44d903;
    }

    @media (min-width: 768px) {
        margin-top: 15px;
        padding: 12px 25px;
        font-size: 16px;
    }
`;

export const Preview = styled.button`
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 14px;
    width: 100%;
    background-color: var(--celeste);
    color: var(--negro);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0277BD;
    }

    @media (min-width: 768px) {
        margin-top: 15px;
        padding: 12px 25px;
        font-size: 16px;
    }
`;

export const LoadButton = styled(SaveButton)`
    background-color: #80DEEA;
    color: var(--negro);

    &:hover {
        background-color: #4DD0E1;
    }
`;

export const FormElementWrapper = styled.div`
    border: ${(props) => (props.isSelected ? '2px solid var(--azul)' : '1px solid var(--verde-oscuro)')};
    padding: 8px;
    background-color: var(--blanco);
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    user-select: none;
    box-shadow: ${(props) => (props.isSelected ? '0 0 10px rgba(0,0,0,0.2)' : 'none')};
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }
`;

export const ColumnWrapper = styled.div`
    border: 2px solid var(--verde-oscuro);
    background-color: var(--verde-claro);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .column-content {
        flex-grow: 1;
        padding: 10px;
        overflow: auto;
    }

    .drop-zone {
        border: 2px dashed var(--verde-oscuro);
        padding: 10px;
        text-align: center;
        color: var(--azul);
        margin-top: 10px;
        cursor: pointer;
        width: 100%;
        border-radius: 8px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: var(--verde-medio-rgba);
        }
    }

    .add-icon {
        font-size: 28px;
        color: var(--verde-oscuro);
    }
`;

export const ColumnHeader = styled.div`
    background-color: var(--verde-oscuro);
    padding: 8px;
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blanco);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const AddColumnButton = styled.button`
    padding: 12px 25px;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
    background-color: var(--limon);
    color: var(--negro);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #53f5b0;
    }

    @media (min-width: 768px) {
        padding: 15px 30px;
        font-size: 18px;
        margin-top: 15px;
    }
`;

export const DeleteColumnButton = styled(AiOutlineDelete)`
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--rojo);
    font-size: 18px;
    cursor: pointer;
    z-index: 10;

    &:hover {
        color: var(--rojo-oscuro);
    }
`;

export const FavoritesIcon = styled(AiFillStar)`
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 28px;
    color: #F6FA80;
    cursor: pointer;
    z-index: 1000;

    &:hover {
        color: #d7fa60;
    }
`;

export const FavoritesItem = styled.div`
    padding: 8px;
    cursor: pointer;
    background-color: var(--verde-claro);
    margin-bottom: 8px;
    text-align: center;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: var(--verde-medio);
    }
`;

export const Modal = styled.div`
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContent = styled.div`
    background-color: var(--blanco);
    margin: 5% auto;
    padding: 20px;
    width: 90%;
    max-height: 80%;
    overflow: auto;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

    @media(min-width: 768px) {
        padding: 30px;
        width: 80%;
    }
`;

export const CloseButton = styled(AiOutlineClose)`
    color: var(--rojo);
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    float: right;

    &:hover {
        color: var(--rojo-oscuro);
    }
`;
// src/components/WelcomeAnimation/WelcomeAnimation.jsx

import React, { useEffect } from 'react';
import { WelcomeContainer, WelcomeMessage } from './WelcomeAnimationStyle';

const WelcomeAnimation = ({ onAnimationEnd }) => {
    useEffect(() => {
        // Temporizador extendido a 5 segundos
        const timer = setTimeout(() => {
            onAnimationEnd();
        }, 5000); // 5000 milisegundos = 5 segundos

        return () => clearTimeout(timer);
    }, [onAnimationEnd]);

    const handleClick = () => {
        onAnimationEnd();
    };

    return (
        <WelcomeContainer onClick={handleClick}>
            <div>
                <WelcomeMessage>Bienvenidos Jueces del ILAN 2024</WelcomeMessage>
            </div>
        </WelcomeContainer>
    );
};

export default WelcomeAnimation;

import React, { useState, useContext, createContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useWebSocket } from "../context/WebSocketContext";
import { useSede } from '../context/SedeContext';
import { SidebarContext } from "../context/SidebarContext";
import {
  Sidebar,
  UserInfo,
  Menu,
  MenuItem,
  SubMenu,
  SubMenuItem,
  ChevronIcon,
  SidebarButton,
  Sede,
  NotificationBellContainer
} from '../views/Dashboard/DashboardStyle';
import profilePicture from '../assets/login/profile/user.png'; // Importa la imagen
import HeartIconAnimation from "./HeartIconAnimation/HeartIconAnimation";
import heartAnimationData from './HeartIconAnimation/AnimationHeart.json';
import animationData from '../assets/profile.json';
import axios from "axios";
import { FaBars, FaTimes, FaUsersCog, FaUserMd, FaUserInjured, FaChartLine, FaCog, FaBusinessTime, FaTools, FaComments } from 'react-icons/fa';

import Lottie from 'react-lottie';
import NotificationBell from "./NotificationBell/NotificationBell";
import { API_BASE_URL } from "../utils/config";

export const LayoutContext = createContext({
  activeSubMenu: '',
  setActiveSubMenu: () => { },
  handleNavigate: () => { }
});

const LayoutSide = ({ children }) => {
  const { sidebarState } = useContext(SidebarContext);
  const { userData, logout } = useContext(AuthContext);
  const { nombreSedeActual, idTipoSede, idSedeActual } = useSede();
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const [visibleSections, setVisibleSections] = useState({});
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const [notificationCount, setNotificationCount] = useState(5);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  const wsContext = useWebSocket();

  const toggleSidebar = () => {
    if (isMobile()) {
      setIsMenuOpen(!isMenuOpen);
    } else {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubMenuClick = (menu) => {
    setActiveSubMenu(activeSubMenu === menu ? '' : menu);
  };

  const handleNavigate = (path) => {
    navigate(path);
    if (isMobile()) {
      setIsMenuOpen(false);
      setIsSidebarOpen(false);
    }
  };

  const isMobile = () => window.innerWidth <= 768;

  // Función para definir qué secciones son visibles según el tipo de sede
  const getVisibleSections = (idTipoSede) => {
    const configSede = {
      1: { // Clínica
        chat: true,
        dashboard: true,
        administrador: false,
        fisioterapeuta: false,
        pacientes: true,
        gestionNegocio: true,
        disenadorDinamico: true,
        configuracion: true,
      },
      2: { // Institución
        chat: true,
        dashboard: true,
        administrador: false,
        fisioterapeuta: true,
        pacientes: true,
        gestionNegocio: true,
        disenadorDinamico: true,
        configuracion: true,
      },
      3: { // Hospital
        chat: true,
        dashboard: true,
        administrador: true,
        fisioterapeuta: true,
        pacientes: true,
        gestionNegocio: true,
        disenadorDinamico: true,
        configuracion: true,
      },
    };

    return configSede[idTipoSede] || {};
  };

  // Actualizamos las secciones visibles cuando cambia idTipoSede
  useEffect(() => {
    if (idTipoSede !== null) {
      const sections = getVisibleSections(idTipoSede);
      setVisibleSections(sections);
    }
  }, [idTipoSede, idSedeActual]);

  const handleLogoutClick = () => {
    logout();
    navigate('/login');
    if (wsContext.closeWebSocket) {
      wsContext.closeWebSocket();
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const animationRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationKey(prevKey => prevKey + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const renderProfilePicture = () => {
    if (sidebarState.profileImageUrl) {
      return <img src={sidebarState.profileImageUrl} alt="Foto de Perfil" style={{ width: '130px', height: '130px', borderRadius: '50%', marginTop: '15px' }} />;
    } else {
      return <Lottie options={{ loop: false, autoplay: true, animationData }} height={200} width={200} />;
    }
  };

  useEffect(() => {}, [nombreSedeActual]);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/cuenta/fotoPerfil/${userData.id_usuario}`);
        if (response.data.success && response.data.fotoPerfilUrl) {
          setProfileImageUrl(response.data.fotoPerfilUrl);
        }
      } catch (error) {
        console.error('Error al obtener la foto de perfil:', error);
      }
    };

    fetchProfileImage();
  }, [userData.id_usuario]);

  return (
      <LayoutContext.Provider value={{ activeSubMenu, setActiveSubMenu, handleNavigate }}>
        <div style={{ display: 'flex' }}>
          <SidebarButton onClick={toggleSidebar}>
            {isMobile() ? (isMenuOpen ? <FaTimes /> : <FaBars />) : (isSidebarOpen ? <FaTimes /> : <FaBars />)}
          </SidebarButton>
          <Sidebar
              open={isSidebarOpen} menuOpen={isMenuOpen} style={{
            transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
            position: 'fixed', top: 0, left: 0, height: '100vh', width: '250px',
            overflowY: 'auto', transition: 'transform 0.3s ease-in-out', fontSize: '14px'
          }}
          >
            <UserInfo>
              {renderProfilePicture()}
              <div style={{ paddingTop: '10px' }}>{userData?.name} {userData?.lastName}</div>
              <div>{userData?.roles.map(role => role.name).join(', ')}</div>
              <Sede>Sede Actual: {nombreSedeActual}</Sede>
            </UserInfo>

            <Menu>
              {visibleSections.chat && (
                  <MenuItem bold onClick={() => handleNavigate('/dashboard/chat')} className="chat">
                    <FaComments /> Chat
                  </MenuItem>
              )}
              {visibleSections.dashboard && (
                  <MenuItem bold onClick={() => handleNavigate('/dashboard/dashboard-indicadores')} className="dashboard">
                    <FaChartLine /> Dashboard
                  </MenuItem>
              )}
              {visibleSections.administrador && (
                  <>
                    <MenuItem bold onClick={() => handleSubMenuClick('Administrador')} className="administrador">
                      <FaUsersCog /> Administrador
                      <ChevronIcon className="fa fa-chevron-up" rotate={activeSubMenu === 'Administrador'} />
                    </MenuItem>
                    <SubMenu active={activeSubMenu === 'Administrador'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/agregar-administrador')} className="administradorSeccionIngreso">Añadir</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/eliminar-administrador')} className="administradorSeccionEliminar">Eliminar</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/actualizar-administrador')} className="administradorSeccionActualizar">Actualizar</SubMenuItem>
                    </SubMenu>
                  </>
              )}
              {visibleSections.fisioterapeuta && (
                  <>
                    <MenuItem bold onClick={() => handleSubMenuClick('Fisioterapeuta')} className="fisioterapeuta">
                      <FaUserMd /> Fisioterapeuta
                      <ChevronIcon className="fa fa-chevron-up" rotate={activeSubMenu === 'Fisioterapeuta'} />
                    </MenuItem>
                    <SubMenu active={activeSubMenu === 'Fisioterapeuta'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ingresar-medico')} className="fisioterapeutaSeccionIngreso">Añadir</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/eliminar-medico')} className="fisioterapeutaSeccionEliminar">Eliminar</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/actualizar-medico')} className="fisioterapeutaSeccionActualizar">Actualizar</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/reingreso-medico')} className="fisioterapeutaSeccionReingreso">ReIngresar</SubMenuItem>
                    </SubMenu>
                  </>
              )}
              {visibleSections.pacientes && (
                  <>
                    <MenuItem bold onClick={() => handleSubMenuClick('Pacientes')} className="paciente">
                      <FaUserInjured /> Pacientes
                      <ChevronIcon className="fa fa-chevron-up" rotate={activeSubMenu === 'Pacientes'} />
                    </MenuItem>
                    <SubMenu active={activeSubMenu === 'Pacientes'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ingresar-paciente')} className="pacienteSeccionIngreso">Ingresar</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/alta-paciente')} className="pacienteSeccionEliminar">Dar de alta</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/reingreso-paciente')} className="pacienteSeccionReingreso">Reingreso</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/calendario-citas')} className="calendarioCitasPaciente">Calendario de citas</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ficha-evolucion')} className="fichaEvolucion">Ficha Evolución</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ficha-clinica')} className="fichaClinica">Ficha clínica</SubMenuItem>
                    </SubMenu>
                  </>
              )}
              {visibleSections.gestionNegocio && (
                  <>
                    <MenuItem bold onClick={() => handleSubMenuClick('GestionNegocio')} className="gestionNegocio">
                      <FaBusinessTime /> Gestión del negocio
                      <ChevronIcon className="fa fa-chevron-up" rotate={activeSubMenu === 'GestionNegocio'} />
                    </MenuItem>
                    <SubMenu active={activeSubMenu === 'GestionNegocio'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/gestion-servicios')} className="gestionServiciosSeccion">Gestión de servicios</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/reporte-servicios')} className="reporteDeServiciosSeccion">Reporte de servicios</SubMenuItem>
                    </SubMenu>
                  </>
              )}
              {visibleSections.disenadorDinamico && (
                  <>
                    <MenuItem bold onClick={() => handleSubMenuClick('DisenadorDinamico')} className="disenadorDinamico">
                      <FaTools /> Diseñador dinámico
                      <ChevronIcon className="fa fa-chevron-up" rotate={activeSubMenu === 'DisenadorDinamico'} />
                    </MenuItem>
                    <SubMenu active={activeSubMenu === 'DisenadorDinamico'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/form-builder')} className="fichasClinicas">Fichas Clínicas</SubMenuItem>
                    </SubMenu>
                  </>
              )}
              {visibleSections.configuracion && (
                  <MenuItem bold onClick={() => handleNavigate('/dashboard/configuracion')} className="configuracion">
                    <FaCog /> Configuración
                  </MenuItem>
              )}

              <MenuItem onClick={handleLogoutClick}>
                &nbsp;Cerrar sesión
                <ChevronIcon className="fa fa-sign-out" rotate={activeSubMenu === 'CerrarSesion'} />
              </MenuItem>
            </Menu>
          </Sidebar>
          <HeartIconAnimation animationData={heartAnimationData} />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: !isMobile() && isSidebarOpen ? '250px' : '0', width: '100%', transition: 'margin-left 0.3s', boxSizing: 'border-box' }}>
            <NotificationBellContainer>
              <NotificationBell />
            </NotificationBellContainer>
            <Outlet />
            <div>
              {children}
            </div>
          </div>
        </div>
      </LayoutContext.Provider>
  );
};

export default LayoutSide;